import React from 'react';
import { ProductConfig } from '@silkpwa/module/react-component/product-config';
import { CertonaRecommendations } from 'ui/component/product/product-widget/certona-recommendations';
import { ProductConfigurator } from 'ui/component/product-configurator';
import { ReviewDisplay } from 'ui/component/native-reviews/display/review-display';
import { IProductAttribute } from '@silkpwa/module/react-component/product-config/util';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { DesktopDescription } from './product-description/desktop-description';
import { Features } from './sections/features';
import { RelatedProducts } from './sections/related-products';
import { UpsellProducts } from './sections/upsell-products';
import { SatisfactionPopouts } from './sections/satisfaction-popouts';
import { MobileAccordion } from './sections/mobile-accordion';
import { IProductConfigData } from '../../product-configurator/product-config';
import { QuestionAnswerDisplay } from '../../question-answer/display/question-answer-display';

export interface IProductConfigChildProps {
    product: IProductAttribute & { sku: string };
}

export const ProductDetailsPage = ({
    product,
}: IProductConfigChildProps) => {
    const reviewsConfig = useConfig();
    const handleOptionChange = (option, configProps) => {
        configProps.reviews.setSort(option.id);
    };
    const productConfig: React.FC<IProductConfigData> = (configProps: IProductConfigData) => (
        <React.Fragment>
            <ProductConfigurator
                config={configProps}
                popouts={<SatisfactionPopouts product={product} />}
            />
            <MobileAccordion
                product={product}
                simpleProduct={configProps.simpleProduct}
            />

            <DesktopDescription
                product={product}
                simpleProduct={configProps.simpleProduct}
            />

            <RelatedProducts product={configProps.product} />
            <UpsellProducts product={configProps.product} />
            <CertonaRecommendations />
            <Features product={configProps.simpleProduct} />
            {reviewsConfig.extension_attributes?.cw_reviews_enabled && (
                <ReviewDisplay
                    productId={product.id}
                    productReviews
                    state={configProps.reviews}
                    handleOptionChange={(option: string) => handleOptionChange(option, configProps)}
                />
            )}
            {reviewsConfig.extension_attributes?.cw_question_answer_enabled && (
                <QuestionAnswerDisplay
                    pageType="product_type"
                    identifier={product.sku}
                    displayAt={`${reviewsConfig.store_config.current_store.id}`}
                    pageSize={5}
                    currentPage={1}
                />
            )}
        </React.Fragment>
    );
    return (
        <ProductConfig productId={product.id}>
            {productConfig}
        </ProductConfig>
    );
};
